import React from "react";
import { type PropsWithChildren } from "react";
import type { NavigateProps } from "react-router-dom";
import { Navigate } from "react-router-dom";

import SwitchCase from "components/Common/SwitchCase";

import LoginStorage from "utils/loginStorage";

const LoginRequired = ({
  children,
  to = "/",
  replace,
  state,
  relative,
}: PropsWithChildren<
  Omit<NavigateProps, "to"> & { to?: NavigateProps["to"] }
>) => {
  return (
    <SwitchCase
      value={LoginStorage.isLogin()}
      caseBy={{
        true: <>{children}</>,
        false: (
          <Navigate
            to={to}
            replace={replace}
            state={state}
            relative={relative}
          />
        ),
      }}
    />
  );
};

export default LoginRequired;
