const fontSize = {
  40: "40px",
  32: "32px",
  28: "28px",
  24: "24px",
  20: "20px",
  18: "18px",
  16: "16px",
  15: "15px",
  13: "13px",
};

const color = {
  main_red: "rgba(255, 45, 46, 1)",
  pastel_red: "rgba(255, 243, 243, 1)",
  white: "rgba(252, 254, 255, 1)",
  black: "rgba(0, 0, 0, 1)",
  gray70: "rgba(128, 137, 145, 1)",
  gray30: "rgba(190, 190, 190, 1)",
  gray10: "rgba(248, 248, 248, 1)",
  notice: "rgba(9, 0, 255, 1)",
  error: "rgba(255, 31, 31, 1)",
  bg: "rgba(248, 248, 248, 1)",
};

const theme = {
  fontSize,
  color,
};

export default theme;
