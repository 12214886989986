import React from "react";

import type { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider as Provider } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";

const QueryClientProvider = ({
  children,
  queryClient,
}: PropsWithChildren<{ queryClient: QueryClient }>) => {
  return <Provider client={queryClient}>{children}</Provider>;
};

export default QueryClientProvider;
