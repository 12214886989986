import React from "react";
import type { PropsWithChildren } from "react";
import type { NavigateProps } from "react-router-dom";
import { Navigate } from "react-router-dom";

import LoginStorage from "utils/loginStorage";

const NonLoginRequired = ({
  children,
  to = "/",
  replace,
  state,
  relative,
}: PropsWithChildren<
  Omit<NavigateProps, "to"> & { to?: NavigateProps["to"] }
>) => {
  const isLogin = LoginStorage.isLogin();
  return isLogin ? (
    <Navigate to={to} replace={replace} state={state} relative={relative} />
  ) : (
    <>{children}</>
  );
};

export default NonLoginRequired;
