import { JWT_KEY } from "config/constant";
import { generateSessionStorage } from "./storage";

const getStorage = () => safeLoginSessionStorage;
const safeLoginSessionStorage = generateSessionStorage();

const accessTokenKey = JWT_KEY;

const LoginStorage = {
  getToken: () => {
    const storage = getStorage();
    return storage.get(accessTokenKey);
  },
  setToken: ({ value }: { value: string }) => {
    const storage = getStorage();
    storage.set(accessTokenKey, value);
  },
  removeToken: () => {
    const storage = getStorage();
    storage.remove(accessTokenKey);
  },
  isLogin: () => {
    const _accessToken = LoginStorage.getToken();
    return !!_accessToken;
  },
};

export default LoginStorage;
