import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import React, { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import packageJson from "../package.json";
import { RecoilRoot } from "recoil";
import { GlobalStyle } from "./components/styles";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import QueryClient from "react-query/QueryClient";
import QueryClientProvider from "react-query/QueryClientProvider";
// import { worker } from "./mocks";
import { ThemeProvider } from "styled-components";
import theme from "config/theme";
import LoginRequired from "hocs/LoginRequired";
import NonLoginRequired from "hocs/NonLoginRequired";
// 이외에 필요한 스타일 파일이 있다면 import 해주세요.

console.log(
  `%cNE(O)RDINARY Project Name : ${packageJson.name}`,
  "color:yellow"
);
console.log(
  `%cNE(O)RDINARY Project Version : ${packageJson.version}`,
  "color:yellow"
);
console.log(
  `%cNE(O)RDINARY Project Made by : ${packageJson.author}`,
  "color:yellow"
);

console.log(`
███╗   ██╗███████╗ ██╗ ██████╗ ██╗ ██████╗ ██████╗ ██╗███╗   ██╗ █████╗ ██████╗ ██╗   ██╗
████╗  ██║██╔════╝██╔╝██╔═══██╗╚██╗██╔══██╗██╔══██╗██║████╗  ██║██╔══██╗██╔══██╗╚██╗ ██╔╝
██╔██╗ ██║█████╗  ██║ ██║   ██║ ██║██████╔╝██║  ██║██║██╔██╗ ██║███████║██████╔╝ ╚████╔╝
██║╚██╗██║██╔══╝  ██║ ██║   ██║ ██║██╔══██╗██║  ██║██║██║╚██╗██║██╔══██║██╔══██╗  ╚██╔╝
██║ ╚████║███████╗╚██╗╚██████╔╝██╔╝██║  ██║██████╔╝██║██║ ╚████║██║  ██║██║  ██║   ██║
╚═╝  ╚═══╝╚══════╝ ╚═╝ ╚═════╝ ╚═╝ ╚═╝  ╚═╝╚═════╝ ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝
`);

const loading = <div>화면을 불러오는 중 입니다.</div>;

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./pages/login"));
const Page404 = React.lazy(() => import("./pages/page404/Page404"));
const Page500 = React.lazy(() => import("./pages/page500/Page500"));
const AdminLogin = React.lazy(() => import("./pages/login/admin"));
const KakaoLogin = React.lazy(() => import("./pages/login/kakaologin"));
const RoomListPage = React.lazy(() => import("./pages/room/list"));
const RoomCreatePage = React.lazy(() => import("./pages/room/edit"));
const RegisterPage = React.lazy(() => import("./pages/register"));

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: Infinity } },
});

// worker.start();

root.render(
  <StrictMode>
    <QueryClientProvider queryClient={queryClient}>
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Suspense fallback={loading}>
            <RouterProvider
              router={createBrowserRouter([
                {
                  path: "/",
                  element: <DefaultLayout />,
                  children: [
                    {
                      path: "/room",
                      element: (
                        <LoginRequired to="/login">
                          <RoomListPage />
                        </LoginRequired>
                      ),
                    },
                    {
                      path: "/room/create",
                      element: (
                        <LoginRequired to="/login">
                          <RoomCreatePage />
                        </LoginRequired>
                      ),
                    },
                  ],
                },
                {
                  path: "/login",
                  element: (
                    <NonLoginRequired>
                      <Login />
                    </NonLoginRequired>
                  ),
                },
                {
                  path: "/register",
                  element: (
                    <NonLoginRequired>
                      <RegisterPage />
                    </NonLoginRequired>
                  ),
                },
                {
                  path: "/admin/login",
                  element: (
                    <NonLoginRequired>
                      <AdminLogin />
                    </NonLoginRequired>
                  ),
                },
                {
                  path: "/kakaologin",
                  element: <KakaoLogin />,
                },
                {
                  path: "/404",
                  element: <Page404 />,
                },
                {
                  path: "/500",
                  element: <Page500 />,
                },
              ])}
            />
          </Suspense>
        </ThemeProvider>
      </RecoilRoot>
    </QueryClientProvider>
  </StrictMode>
);
